import React, {useState, useEffect} from "react";
import {
    Form,
    Input,
    Button,
    Card,
    Select,
    DatePicker,
    Dropdown,
    notification,
    Radio,
    Upload,
    Modal,
    message,
    Checkbox,
    Popover
} from 'antd';
import jsonfile from './voucher.json'
import {CisUI} from '../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../util/config";
import axios from "../../../util/Api";
import {Link, useHistory} from "react-router-dom";
import SelectData from "../../../util/selectdata";
import moment from "moment";
import IntlMessages from "../../../util/IntlMessages";
import {UploadOutlined} from "@ant-design/icons";
import * as XLXS from "xlsx";

const {Option} = Select;
const {TextArea} = Input;

const MultipleVoucherNew = (props) => {

    const formRef = React.createRef();

    const [loading, setLoading] = useState([]);


    const [state, setState] = useState({
        data: [],
        rows: []
    })

    const [checkStatus, setCheckStatus] = useState(false);

    const [loaded, setLoaded] = useState(false);
    const [vendor, setVendor] = useState([]);
    const [podata, setPOdata] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [accounts, setAccount] = useState([]);
    const [bankaccounts, setBankAccounts] = useState([]);
    const [isCash, setIsCash] = useState(false);
    const [cashaccount, setCashAccount] = useState([]);
    const [mainaccount, setMainAccount] = useState([]);
    const [accountchild, setChildAccount] = useState([]);
    const [subaccount, setSubAccount] = useState([]);
    const [payable, setPayable] = useState([]);
    const [fund, setFund] = useState([]);
    const [expense, setExpense] = useState([]);
    const [project, setProject] = useState([]);
    const [allbankaccounts, setAllBankAccounts] = useState([]);
    const [isLoaded, setIsLoaded] = useState(0);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        // document.getElementById("file").file.value = "";
        setState({rows: []});
        setIsModalOpen(true);
        // document.getElementById("file").value = "";
    };
    const handleOk = () => {
        // onChangeExcel();
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [beneficiaryData, setBeneficiaryData] = useState({
        department: [],
        users: [],
        ministry: [],
        pentsos: [],
        area: [],
        district: [],
        local: [],
        subsector: [],
        committee: [],
    });


    const [doc, setDoc] = useState({});
    const [segment, setSegment] = useState(1);
    const [selectedSegment, setSelectedSegment] = useState([]);

    const [voucherType, setVoucherType] = useState("Payment");

    const onChangeRadio = e => {
        console.log('radio checked', e.target.value);
        setVoucherType(e.target.value);
    };

    const onCheckChange = (e) => {
        console.log(e);
        if (e.target.checked === true) {
            setCheckStatus(true);
        } else {
            setCheckStatus(false);
        }
    }

    const [bill, setBill] = useState(
        {
            totalReceipt : 0,
            totalPayment : 0
        }
    );

    const [comp, setComp] = useState(
        {
            com_address: "",
            com_city: "",
            com_state: "",
            com_zip_code: ""
        }
    );

    const [vend, setVend] = useState(
        {
            vendor_address: "",
            vendor_city: "",
            vendor_state: "",
            vendor_zip_code: "",
            shipping_method: ""
        }
    );

    const handleChange = (e) => {
        setComp({
            [e.target.name]: e.target.value
        })
    }

    const BankName = SelectData("banknames");
    const Benificiary = SelectData("accountbenificary");
    const [benificiariesData, setBenificiaryData] = useState([]);
    const PaymentMethod = SelectData("paymentmethod");
    const BankAccount = SelectData("bankaccount");
    let BenificaryData = [];

    // const Area = SelectData("allarea");
    // const District = SelectData("alldistrict");
    // const Local = SelectData("alllocal");


    // const onBenificaryChange = (e) => {
    //     //setBenificiaryselected(e.target.value);
    //     console.log("Selected : ", e);
    //     if (e === 2) {
    //         BenificaryData = customer;
    //         setBenificiaryData(customer);
    //     } else if (e === 3) {
    //         BenificaryData = vendor;
    //         setBenificiaryData(vendor);
    //     } else if (e === 4) {
    //         BenificaryData = employee;
    //         setBenificiaryData(employee);
    //     } else if (e === 5) {
    //         BenificaryData = accounts;
    //         setBenificiaryData(accounts);
    //     } else if (e === 6) {
    //         BenificaryData = beneficiaryData.department;
    //         setBenificiaryData(beneficiaryData.department);
    //     } else if (e === 7) {
    //         BenificaryData = Area;
    //         setBenificiaryData(Area);
    //     } else if (e === 8) {
    //         BenificaryData = beneficiaryData.users;
    //         setBenificiaryData(beneficiaryData.users);
    //     } else if (e === 9) {
    //         BenificaryData = beneficiaryData.users;
    //         setBenificiaryData(beneficiaryData.users);
    //     } else if (e === 10) {
    //         // console.log(e);
    //         // console.log(beneficiaryData.ministry);
    //         BenificaryData = beneficiaryData.ministry;
    //         setBenificiaryData(beneficiaryData.ministry);
    //     } else if (e === 11) {
    //         BenificaryData = beneficiaryData.pentsos;
    //         setBenificiaryData(beneficiaryData.pentsos);
    //     } else if (e === 12) {
    //         BenificaryData = District;
    //         setBenificiaryData(District);
    //     } else if (e === 13) {
    //         BenificaryData = Local;
    //         setBenificiaryData(Local);
    //     }
    //     else if (e === 14) {
    //         BenificaryData = [];
    //         setBenificiaryData([]);
    //     }
    //     else if (e === 15) {
    //         BenificaryData = beneficiaryData.subsector;
    //         setBenificiaryData(beneficiaryData.subsector);
    //     }
    //
    //     form.setFieldsValue({
    //         benificiary_id: null
    //     });
    //
    // }

    const onBenificaryChange = (e) => {
        //setBenificiaryselected(e.target.value);
        console.log("Selected : ", e);
        if (e === 2) {
            BenificaryData = customer;
            setBenificiaryData(customer);
        } else if (e === 3) {
            BenificaryData = vendor;
            setBenificiaryData(vendor);
        } else if (e === 4) {
            BenificaryData = employee;
            setBenificiaryData(employee);
        } else if (e === 5) {
            BenificaryData = accounts;
            setBenificiaryData(accounts);
        } else if (e === 6) {
            BenificaryData = beneficiaryData.department;
            setBenificiaryData(beneficiaryData.department);
        } else if (e === 7) {
            BenificaryData = beneficiaryData.area;
            setBenificiaryData(beneficiaryData.area);
        } else if (e === 8) {
            BenificaryData = employee;
            setBenificiaryData(employee);
        } else if (e === 9) {
            BenificaryData = beneficiaryData.users;
            setBenificiaryData(beneficiaryData.users);
        } else if (e === 10) {
            // console.log(e);
            // console.log(beneficiaryData.ministry);
            BenificaryData = beneficiaryData.ministry;
            setBenificiaryData(beneficiaryData.ministry);
        } else if (e === 11) {
            BenificaryData = beneficiaryData.pentsos;
            setBenificiaryData(beneficiaryData.pentsos);
        } else if (e === 12) {
            BenificaryData = beneficiaryData.district;
            setBenificiaryData(beneficiaryData.district);
        } else if (e === 13) {
            BenificaryData = beneficiaryData.local;
            setBenificiaryData(beneficiaryData.local);
        }
        else if (e === 14) {
            BenificaryData = beneficiaryData.committee;
            setBenificiaryData(beneficiaryData.committee);
        }
        else if (e === 15) {
            BenificaryData = beneficiaryData.subsector;
            setBenificiaryData(beneficiaryData.subsector);
        }

        form.setFieldsValue({
            benificiary_id: null
        });

    }

    const [polist, setPolist] = useState({
        po_id : 0,
        po_number : "",
        date: null,
        due_date: null,
        reference_no: "",
        company: "",
        company_id: null,
        benificiary_id: null,
        vendor_id: null,
        vendor: "",
        payable_id: null,
        fund_program_id : null,
        func_expense_id : null,
        amount : 0,
        description : "",
        remark : "",
        status : 0,
        approved_by : "",
        approved_at : "",
    });

    const onBankChange = (value) => {
        const vl = value+Math.floor(Math.random() * 900000);
        form.setFieldsValue({reference_no : vl});
    }


    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }

    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.many_voucher_add;
    let redirectto = "../" + jsonfile.urls.list;

    if (isEdit === 1) {
        //userData = RemoteJson();
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../vendor/newbillt?po="+userID;

        // console.log("selected : "+ JSON.stringify(company));
        //console.log("address : "+ company.address_line_1+", "+company.address_line_2);
        //document.getElementById("input_com_address").value = "Test";
    }


    let Vendors = [];
    const Companies = SelectData("company/companylists");


    const [form] = Form.useForm();
    const history = useHistory();

    useEffect(() => {
        const comID = CisUI().getUserInfo('com_id');
        if(comID > 0) {
            onCompanyChangeForComapny(comID);
        }
    }, []);

    const onCompanyChangeForComapny = value => {
        console.log("selected : "+ value);
        if(value > 0) {
            const company = value;
            axios.get("billentrydata?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setVendor(res.data.vendor);
                        setAccount(res.data.account);
                        setPayable(res.data.payable);
                        setFund(res.data.fund);
                        setExpense(res.data.expense);
                        setBankAccounts(res.data.bank);
                        setAllBankAccounts(res.data.bank);
                        setEmployee(res.data.employee);
                        setCustomer(res.data.customer);
                        setProject(res.data.project);
                        setCashAccount(res.data.cashaccount);
                        setDoc(res.data.doc_class);
                        setSelectedSegment(res.data.doc_class[1]);
                        setMainAccount(res.data.main_account);
                        setChildAccount(res.data.child_account);
                        setIsLoaded(1);
                        setBeneficiaryData({
                            department: res.data.department,
                            users: res.data.users,
                            ministry: res.data.ministry,
                            pentsos: res.data.pentsos,
                            subsector: res.data.subsector,
                            committee: res.data.committee,
                            area: res.data.area,
                            district: res.data.district,
                            local: res.data.local
                        })

                        handleAddRow();

                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            formRef.current.setFieldsValue({
                vendor: "",
                accounts_payable: "",
                accounts_id: ""
            });
        }
    }


    if(loaded) {
        OrderDetails();
    }

    const OrderDetails = async () => {
        return await axios.get(jsonfile.urls.view + "/" + userID)
            .then((res) => {
                if(res.data.status === 1) {
                    const podata = res.data.podata;
                    //onCompanyChange(podata.company_id);
                    setVendor(res.data.vendors);
                    setPOdata(res.data.podata);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const onFinish = (values) => {
        // setLoading({loading: true})

        let newRows = [];
        state.rows.map((items,ind)=>{
            newRows.push({
                id: items.id,
                account_id: items.account_id,
                sub_account_id: items.sub_account_id,
                description: items.description,
                checkno: items.checkno,
                beneficiary_source : items.beneficiary_source,
                beneficiary_id : items.beneficiary_id,
                payment_method : items.payment_method,
                bank_account_id : items.bank_account_id,
                amount : items.amount,
                files : items.files,
                file_location : items.file_location
            });
        });

        values["items"] = newRows;

        // values["items"] = state.rows;
        values["summary"] = bill;
        if (checkStatus === true) {
            values["checque_status"] = 1;
        } else {
            values["checque_status"] = 0;
        }
        // console.log(JSON.stringify(values));
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                // console.log(res)
                if (res.data.status === 1) {
                    CisUI().showAlert("Success","success",res.data.msg);
                    document.getElementById("loader").style.display = "none";
                    history.push("../entries/mvoucherdetails/"+res.data.voucher_id);
                } else {
                    CisUI().showAlert("Alert","warning",res.data.msg);
                    document.getElementById("loader").style.display = "none";
                }
                setLoading({loading: false});
            })
            .catch(errors => {
                // console.log(errors.response.data.errors);
                setLoading({loading: false});
                document.getElementById("loader").style.display = "none";
            });
    };


    useEffect(() => {
        if(isEdit === 1) {
            //OrderDetails();
            const comID = CisUI().getUserInfo('com_id') || 0;
            if(CisUI().getUserInfo('com_id') > 0) {
                onCompanyChangeLocal(comID);
            }
        }
        else {
            const comID = CisUI().getUserInfo('com_id') || 0;
            if(CisUI().getUserInfo('com_id') > 0) {
                onCompanyChangeLocal(comID);
            }
        }
    }, []);


    const onCompanyChange = value => {
        console.log("selected : "+ value);
        let fl = Companies.filter(item => item.id === value);
        if(fl.length > 0) {
            const company = fl[0];
            axios.get("vendor/vendorinfobycompany?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setVendor(res.data.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const onCompanyChangeLocal = value => {
        console.log("selected : "+ value);
        if(value > 0) {
            let company = {};
            axios.get("vendor/vendorinfobycompany?com_id="+value)
                .then((res) => {
                    console.log(res.data);
                    if(res.data.status === 1) {
                        setVendor(res.data.data);
                        company = res.data.company;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const ItemCellChange = (idx,cname="") => e => {
        let name = "";
        let value = null;
        if(cname === "") {
            name = e.target.name;
            value = e.target.value;
        }
        else {
            name = cname;
            value = e;
        }

        const rows = [...state.rows];
        // console.log("Index:"+ idx);
        // console.log("selectedITems:"+ JSON.stringify(rows[idx]));
        // console.log("Event:"+ name);
        // console.log("EventValue:"+ value);

        if(name === "account_id") {
            const sub = accountchild[parseInt(value)] || [];
            setSubAccount(sub);
            rows[idx] = {
                account_id: value,
                sub_account_id: rows[idx].sub_account_id,
                doc_class: rows[idx].doc_class,
                selectedsegment: rows[idx].selectedsegment,
                description: rows[idx].description,
                checkno: rows[idx].checkno,
                beneficiary_source : rows[idx].beneficiary_source,
                beneficiary_id : rows[idx].beneficiary_id,
                payment_method : rows[idx].payment_method,
                bank_account_id : rows[idx].bank_account_id,
                amount: rows[idx].amount,
                files : rows[idx].files,
                file_location : rows[idx].file_location,
                subaccounts: sub,
                beneficiary: rows[idx].beneficiary
            };
        }
        else if(name === "sub_account_id") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                sub_account_id: value,
                doc_class: rows[idx].doc_class,
                selectedsegment: rows[idx].selectedsegment,
                description: rows[idx].description,
                checkno: rows[idx].checkno,
                beneficiary_source : rows[idx].beneficiary_source,
                beneficiary_id : rows[idx].beneficiary_id,
                payment_method : rows[idx].payment_method,
                bank_account_id : rows[idx].bank_account_id,
                amount: rows[idx].amount,
                files : rows[idx].files,
                file_location : rows[idx].file_location,
                subaccounts: rows[idx].subaccounts,
                beneficiary: rows[idx].beneficiary
            };
        }
        else if(name === "description") {
            //const amount = value * rows[idx].price;
            rows[idx] = {
                account_id: rows[idx].account_id,
                sub_account_id: rows[idx].sub_account_id,
                doc_class: rows[idx].doc_class,
                selectedsegment: rows[idx].selectedsegment,
                description: value,
                checkno: rows[idx].checkno,
                beneficiary_source : rows[idx].beneficiary_source,
                beneficiary_id : rows[idx].beneficiary_id,
                payment_method : rows[idx].payment_method,
                bank_account_id : rows[idx].bank_account_id,
                amount: rows[idx].amount,
                files : rows[idx].files,
                file_location : rows[idx].file_location,
                subaccounts: rows[idx].subaccounts,
                beneficiary: rows[idx].beneficiary
            };
        }
        else if(name === "amount") {
            //CalculateCell();
            //const amount = value * rows[idx].qty;
            rows[idx] = {
                account_id: rows[idx].account_id,
                sub_account_id: rows[idx].sub_account_id,
                doc_class: rows[idx].doc_class,
                selectedsegment: rows[idx].selectedsegment,
                description: rows[idx].description,
                checkno: rows[idx].checkno,
                beneficiary_source : rows[idx].beneficiary_source,
                beneficiary_id : rows[idx].beneficiary_id,
                payment_method : rows[idx].payment_method,
                bank_account_id : rows[idx].bank_account_id,
                amount: value,
                files : rows[idx].files,
                file_location : rows[idx].file_location,
                subaccounts: rows[idx].subaccounts,
                beneficiary: rows[idx].beneficiary
            };
        }
        else if(name === "checkno") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                sub_account_id: rows[idx].sub_account_id,
                doc_class: rows[idx].doc_class,
                selectedsegment: rows[idx].selectedsegment,
                description: rows[idx].description,
                checkno: value,
                beneficiary_source : rows[idx].beneficiary_source,
                beneficiary_id : rows[idx].beneficiary_id,
                payment_method : rows[idx].payment_method,
                bank_account_id : rows[idx].bank_account_id,
                amount: rows[idx].amount,
                files : rows[idx].files,
                file_location : rows[idx].file_location,
                subaccounts: rows[idx].subaccounts,
                beneficiary: rows[idx].beneficiary
            };
        }
        else if(name === "beneficiary_source") {
            // onBenificaryChange(value);

            // const e = value;
            let BF = [];
            if (value === 2) {
                BF = customer;
            } else if (value === 3) {
                BF = vendor;
            } else if (value === 4) {
                BF = employee;
            } else if (value === 5) {
                BF = accounts;
            } else if (value === 6) {
                BF = beneficiaryData.department;
            } else if (value === 7) {
                BF = beneficiaryData.area;
            } else if (value === 8) {
                BF = beneficiaryData.users;
            } else if (value === 9) {
                BF = beneficiaryData.users;
            } else if (value === 10) {
                // console.log(e);
                // console.log(beneficiaryData.ministry);
                BF = beneficiaryData.ministry;
            } else if (value === 11) {
                BF = beneficiaryData.pentsos;
            } else if (value === 12) {
                BF = beneficiaryData.district;
            } else if (value === 13) {
                BF = beneficiaryData.local;
            }
            else if (value === 14) {
                BF = beneficiaryData.committee;
            }
            else if (value === 15) {
                BF = beneficiaryData.subsector;
            }

            rows[idx] = {
                account_id: rows[idx].account_id,
                sub_account_id: rows[idx].sub_account_id,
                doc_class: rows[idx].doc_class,
                selectedsegment: rows[idx].selectedsegment,
                description: rows[idx].description,
                checkno : rows[idx].checkno,
                beneficiary_source : value,
                beneficiary_id : rows[idx].beneficiary_id,
                payment_method : rows[idx].payment_method,
                bank_account_id : rows[idx].bank_account_id,
                amount: rows[idx].amount,
                files : rows[idx].files,
                file_location : rows[idx].file_location,
                subaccounts: rows[idx].subaccounts,
                beneficiary: BF
            };
        }
        else if(name === "beneficiary_id") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                sub_account_id: rows[idx].sub_account_id,
                doc_class: rows[idx].doc_class,
                selectedsegment: rows[idx].selectedsegment,
                description: rows[idx].description,
                checkno : rows[idx].checkno,
                beneficiary_source : rows[idx].beneficiary_source,
                beneficiary_id : value,
                payment_method : rows[idx].payment_method,
                bank_account_id : rows[idx].bank_account_id,
                amount: rows[idx].amount,
                files : rows[idx].files,
                file_location : rows[idx].file_location,
                subaccounts: rows[idx].subaccounts,
                beneficiary: rows[idx].beneficiary
            };
        }
        else if(name === "payment_method") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                sub_account_id: rows[idx].sub_account_id,
                doc_class: rows[idx].doc_class,
                selectedsegment: rows[idx].selectedsegment,
                description: rows[idx].description,
                checkno : rows[idx].checkno,
                beneficiary_source : rows[idx].beneficiary_source,
                beneficiary_id : rows[idx].beneficiary_id,
                payment_method : value,
                bank_account_id : rows[idx].bank_account_id,
                amount: rows[idx].amount,
                files : rows[idx].files,
                file_location : rows[idx].file_location,
                subaccounts: rows[idx].subaccounts,
                beneficiary: rows[idx].beneficiary
            };
        }
        else if(name === "bank_account_id") {
            rows[idx] = {
                account_id: rows[idx].account_id,
                sub_account_id: rows[idx].sub_account_id,
                doc_class: rows[idx].doc_class,
                selectedsegment: rows[idx].selectedsegment,
                description: rows[idx].description,
                checkno : rows[idx].checkno,
                beneficiary_source : rows[idx].beneficiary_source,
                beneficiary_id : rows[idx].beneficiary_id,
                payment_method : rows[idx].payment_method,
                bank_account_id : value,
                amount: rows[idx].amount,
                files : rows[idx].files,
                file_location : rows[idx].file_location,
                subaccounts: rows[idx].subaccounts,
                beneficiary: rows[idx].beneficiary
            };
        }

        console.log(rows);

        setState({
            rows: rows
        });

        let totalsReceipt = 0
        let totalsPayment = 0
        let totalAmount = 0
        rows.map((item, idx) => {
            totalAmount += parseFloat(item.amount);
        });


        setBill({
            totalReceipt : totalAmount
        });

    };


    const onPaymentMethodChange = (e) => {
        const value = e;
        console.log("mehtod" + value);
        if(value === 1) {
            //document.getElementById("bankaccount").style.display = "block";
            setIsCash(true);
        }
        else {
            //document.getElementById("bankaccount").style.display = "none";
            setIsCash(false);
        }
        formRef.current.setFieldsValue({
            bank_account_id : null,
            reference : ""
        });
    }

    const onBankAccountChange = (value) => {
        let ref = "";
        if(!isCash) {
            bankaccounts.map((items, index) => {
                if (items.id === value) {
                    if (items.last_issued_check > 0) {
                        ref = parseInt(items.last_issued_check) + 1;
                    } else {
                        ref = items.starting_check_no;
                    }
                }
            });

            // console.log("RefNo : " + ref);

            form.setFieldsValue({
                check_no: ref,
            });
        }
    }


    const handleAddRow = () => {
        const item = {
            account_id : null,
            sub_account_id : null,
            description : "",
            beneficiary_source : null,
            beneficiary_id : null,
            payment_method : 2,
            bank_account_id : null,
            check_no : null,
            checkno : "",
            amount : 0,
            files : [],
            file_location : [],
            subaccounts: [],
            beneficiary: []
        };
        setState({
            rows: [...state.rows, item]
        });
    };

    useEffect(() => {
        // handleAddRow();
    }, []);

    const handleRemoveRow = idx => e => {
        // console.log(idx);
        // console.log(state.rows);
        let filteredArray = state.rows.filter((item, index) => index !== idx)
        setState({rows: filteredArray});
    };

    // useEffect(() => {
    //     handleAddRow();
    // }, []);


    const onChangeAccount = (e) => {
        const sub = accountchild[parseInt(e)] || [];
        setSubAccount(sub);
        form.setFieldsValue({
            sub_account_id: null
        });
    }


    const token = localStorage.getItem('token');
    let formData = new FormData();
    let fileUID = "";
    const uplod = {
        name: 'file',
        action: Config.apiserver+"fileupload",
        method : 'post',
        headers: {
            'Authorization': token,
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
            if (!isJpgOrPng) {
                message.error('You can only upload jpg, png,and pdf file only!');
            }
            return isJpgOrPng;
        },
        onChange(info) {
            formData.append('file', info.file);
            fileUID = info.file.uid;
            console.log("index : "+info.file.uid);
            console.log(JSON.stringify(info));
            if(info.file.status === "done") {
                if (info.file.xhr.status === 200) {
                    console.log("ServerResponse : "+info.file.response);
                    const data = info.file.response;
                    if(data.status === 1) {
                        const list = [...state.rows];
                        let files = list[data.line_index].files || [];
                        let file_location = list[data.line_index].file_location || [];
                        files.push(data.data.id);
                        file_location.push(data.data.location);
                        list[data.line_index].files = files;
                        list[data.line_index].file_location = file_location;
                        setState({rows: list});
                        console.log("Files : "+JSON.stringify(list[data.line_index]));
                        message.success(`${info.file.name} file uploaded successfully`);
                    }

                } else {
                    message.error(`${info.file.name} file upload failed.`);
                }
            }
        }
    }

    var totalAmount = 0;

    const formField = (

        <>
            <div className="row">

                <div className="col-md-3">
                    <Form.Item
                        name="voucher_type"
                        label="Voucher Type"
                        initialValue={"Payment"}
                        rules={[
                            {
                                required: true,
                                message: "Select a voucher type"
                            },
                        ]}
                    >
                        <Radio.Group onChange={onChangeRadio} value={voucherType}>
                            <Radio value={"Payment"}>Payment</Radio>
                            <Radio value={"Receipt"} style={voucherType === 'Receipt' ? {color: "green"} : {}} >Receipt</Radio>
                        </Radio.Group>

                    </Form.Item>
                </div>

                <div className="col-md-3">
                    <Form.Item
                        name="date"
                        label="Date"
                        initialValue={polist.date}
                        rules={[
                            {
                                required: true,
                                message: "Select a date"
                            },
                        ]}
                    >
                        <DatePicker disabledDate={(current) => current.isAfter(moment())} format={CisUI().dateFormat} format={CisUI().dateFormat} className="gx-mb-3 gx-w-100"
                                    placeholder="Date"/>
                    </Form.Item>
                </div>

                {
                    CisUI().getUserInfo('com_id') > 0 ? <></> :
                        <div className="col-md-3">
                            <Form.Item
                                name="com_id"
                                initialValue={polist.company_id}
                                label="Company"
                                rules={[
                                    {
                                        required: true,
                                        message: "Select an company"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select an company"
                                    optionFilterProp="children"
                                    onChange={onCompanyChange}
                                    filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                >
                                    {
                                        Companies.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                }

                <div className="col-md-3">
                    <Form.Item
                        name="bankname_id"
                        initialValue={polist.bankname_id}
                        label="Bank"
                        rules={[
                            {
                                required: false,
                                message: "Select a bank"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a bank"
                            optionFilterProp="children"
                            onChange={onBankChange}
                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                        >
                            {
                                BankName.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-3">
                    <Form.Item
                        name="reference_no"
                        label="Voucher No#"
                        initialValue={Math.floor(Math.random() * 900000)}
                        rules={[
                            {
                                required: true,
                                message: "Enter voucher number"
                            },
                        ]}
                    >
                        <Input readOnly={true} className="gx-mb-3 gx-w-100 readonly" placeholder="Enter a reference number"/>
                    </Form.Item>
                </div>

                <div className="col-md-3">
                    <Form.Item
                        name="payment_method"
                        label="Payment Method"
                        initialValue={2}
                        rules={[
                            {
                                required: true,
                                message: "Select payment method"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select payment method"
                            optionFilterProp="children"
                            onChange={onPaymentMethodChange}
                            allowClear={true}
                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                        >
                            {
                                PaymentMethod.map((items, index) =>
                                    <Option key={++index} value={items.id}>{items.name}</Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-3" id="bankaccount" >
                    <Form.Item
                        name="bank_account_id"
                        label="Bank Account/Cash Account"
                        rules={[
                            {
                                required: true,
                                message: "Select a bank account"
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a bank account"
                            optionFilterProp="children"
                            onChange={onBankAccountChange}
                            allowClear={true}
                            filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                        >
                            {
                                isCash ?
                                    cashaccount.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                    )
                                    :
                                    bankaccounts.map((items, index) =>
                                        <Option key={++index} value={items.id}>{items.account_no}-{items.account_name}({items.bank_name})</Option>
                                    )
                            }
                        </Select>
                    </Form.Item>
                </div>

                <div className="col-md-2">
                    <Form.Item
                        name="check_no"
                        label="Cheque No#"
                        initialValue={polist.remark}
                        rules={[
                            {
                                required: false,
                                message: "Enter cheque no"
                            },
                        ]}
                    >
                        <Input placeholder="Enter cheque/reference no"/>
                    </Form.Item>
                </div>

                <div className="col-md-2">
                    <Form.Item
                        name="remark"
                        label="Remark/Memo"
                        initialValue={polist.remark}
                        rules={[
                            {
                                required: false,
                                message: "Enter something here"
                            },
                        ]}
                    >
                        <TextArea placeholder="Enter something here"/>
                    </Form.Item>
                </div>

                <div className="col-md-2 col-sm-4">
                    <Form.Item
                        name="check_status"
                        label="Cheque Cleared?"
                        initialValue={polist.check_status}
                        valuePropName="Yes"
                        rules={[
                            {
                                required: false,
                                message: "Enter something here"
                            },
                        ]}
                    >
                        <Checkbox checked={checkStatus} onChange={onCheckChange} value={checkStatus}/>
                    </Form.Item>
                </div>

            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                            <h2>Voucher Items</h2>
                        </div>
                        <div className="col-md-6" style={{textAlign : "right"}}>
                            <a className="btn btn-default" style={{background: '#d9e8f4'}}  onClick={showModal}>Excel Import</a>
                            <Popover placement="bottomRight" title={"Template"} content={<>
                                <div>
                                    <a href={Config.apiserver+"areasheetexport?token="+localStorage.getItem("token")} target="_blank">
                                        <i className="fas fa-download"/> Area
                                    </a>
                                </div>
                                <div>
                                    <a href={Config.apiserver+"employeesheetexport?token="+localStorage.getItem("token")} target="_blank">
                                        <i className="fas fa-download"/> Staff/Employee
                                    </a>
                                </div>
                            </>} trigger="click">
                                <button className="btn btn-default" style={{background: '#d9e8f4'}}>Template <i className="fas fa-ellipsis-v"></i></button>
                            </Popover>
                            <a className="btn btn-primary" onClick={handleAddRow}>Add More</a>
                        </div>
                    </div>
                    <table id="itemtable" className="ptable table table-bordered">
                        <thead>
                        <tr>
                            <th></th>
                            <th>GL Code / Account</th>
                            <th>Beneficiary</th>
                            <th style={{width : '300px'}}>Item Description</th>
                            {
                                voucherType === "Receipt" ? <th style={{width : '100px'}}>Receipt</th> : <th>Payment</th>
                            }
                            <th style={{width: '120px'}}>Attachment</th>
                        </tr>
                        </thead>
                        <tbody>
                        {state.rows.map((item, idx) => (
                            <tr id="addr0" key={idx}>
                                <td style={{width : '30px'}}><a onClick={handleRemoveRow(idx)} className="btn btn-primary btn-circle"><i className="fa fa-trash"></i></a></td>
                                <td>
                                    <Select
                                        showSearch
                                        placeholder="Select account"
                                        optionFilterProp="children"
                                        style={{width : '100%'}}
                                        defaultValue={item.account_id}
                                        onChange={ItemCellChange(idx,"account_id")}
                                        filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                    >
                                        {
                                            mainaccount.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                    <br/> <br/>
                                    {
                                        item.subaccounts.length > 0 ?
                                            <Select
                                                showSearch
                                                placeholder="Select a sub account"
                                                optionFilterProp="children"
                                                style={{width : '100%'}}
                                                defaultValue={item.sub_account_id}
                                                onChange={ItemCellChange(idx,"sub_account_id")}
                                                filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                            >
                                                {
                                                    item.subaccounts.map((items, index) =>
                                                        <Option key={++index} value={items.id}>{items.name}</Option>
                                                    )
                                                }
                                            </Select> : <></> }
                                </td>

                                <td>
                                    <Select
                                        showSearch
                                        placeholder="Select a beneficiary source"
                                        optionFilterProp="children"
                                        style={{width : '100%'}}
                                        defaultValue={item.beneficiary_source}
                                        onChange={ItemCellChange(idx,"beneficiary_source")}
                                        filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                    >
                                        {
                                            Benificiary.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                    <br /> <br />
                                    <Select
                                        showSearch
                                        placeholder="Select a beneficiary"
                                        optionFilterProp="children"
                                        style={{width : '100%'}}
                                        defaultValue={item.beneficiary_id}
                                        onChange={ItemCellChange(idx,"beneficiary_id")}
                                        filterOption={(input, option) => option.props.children?.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0 || false}
                                    >
                                        {
                                            item.beneficiary.map((items, index) =>
                                                <Option key={++index} value={items.id}>{items.name}</Option>
                                            )
                                        }
                                    </Select>
                                </td>


                                <td>
                                    <input className="form-control" placeholder="Enter Item Description" name="description" value={item.description} onChange={ItemCellChange(idx)}  />
                                </td>
                                {
                                    voucherType === "Receipt" ?
                                        <td style={{width : '100px'}}><input className="form-control text-right" style={voucherType === 'Payment' ? {color: "#545454", textAlign : 'right'} : {color: "green" , textAlign : 'right'}} placeholder="Receipt" name="amount" value={state.rows[idx].amount} onChange={ItemCellChange(idx)}  /></td>
                                        : <td style={{width : '100px'}}><input className="form-control text-right" style={voucherType === 'Payment' ? {color: "#545454", textAlign : 'right'} : {color: "green" , textAlign : 'right'}} name="amount" placeholder="Payment" value={state.rows[idx].amount} onChange={ItemCellChange(idx)}  /></td>
                                }
                                <td>
                                    <Upload data= {
                                        {
                                            file : formData,
                                            type : "manyvouher",
                                            file_id : fileUID,
                                            row_id : item.id,
                                            line_index : idx
                                        }
                                    } {...uplod}>
                                        <Button icon={<UploadOutlined />}></Button>
                                    </Upload>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td style={{textAlign : 'right', fontWeight : 'bold'}} colSpan={4}>Total</td>
                            {
                                voucherType === "Receipt" ?
                                    <td style={{textAlign : 'right', fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1(bill.totalReceipt || 0)}</td>
                                    :<td style={{textAlign : 'right', fontWeight : 'bold'}}>{CisUI().getCurrencyFormated1(bill.totalReceipt || 0)}</td>
                            }
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </>
    )

    const runData = () => {

    }

    const onChangeExcel = (e) => {
        setState({rows: []});
        let file = e.target.files[0];
        // let file = docu;
        // setDataObject([]);
        let debitT = 0;
        let creditT = 0;
        let newArray = [];
        if(file !== "") {
            if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/x-vnd.oasis.opendocument.spreadsheet' || file.type === 'application/vnd.ms-excel') {
                // console.log(file);
                document.getElementById("loader").style.display = "block";
                let reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = (e) => {
                    const bufferArray = e.target.result;
                    const wb = XLXS.read(bufferArray, {type: 'buffer'});
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    const data1 = XLXS.utils.sheet_to_json(ws);
                    // console.log(data1);
                    // setData(data1);

                    const subsChars = o => Object.fromEntries(Object.entries(o).map(
                        ([k, v]) => [k.replace(/[ \W]/g, m => m === ' ' ? '_' : '').toLowerCase(), v]
                    ))

                    newArray = data1.map(subsChars)

                    let newObject = [];
                    if (newArray.length <= 202) {

                        newArray?.map((dt, index) => {

                                // console.log(dt);
                                let allow = 0;
                                if ((dt["mainaccount"] !== undefined)) {
                                    allow = 1;
                                }

                                if (allow === 1) {
                                    // console.log("found"+dt["refid"]);
                                    const mainAccountD = dt["mainaccount"] || null;
                                    const subAccount = dt["subaccount"] || null;
                                    const refID = dt["refid"] || 0;
                                    const beneficiaryD = dt["beneficiary"] || "";
                                    const beneficiaryInfo = dt["beneficiaryname"] || "";
                                    const description = dt["description"] || "";

                                    const actFind = mainaccount.filter((item) => item.account_number === parseInt(mainAccountD));
                                    // console.log(actFind);
                                    let accountID = null;
                                    let subAccountID = null;
                                    let subAccountList = [];
                                    if (actFind.length > 0) {
                                        accountID = actFind[0]["id"];
                                        subAccountList = accountchild[accountID];
                                    }

                                    const subActFind = subAccountList.filter((item) => item.account_number === parseInt(subAccount));
                                    if (subActFind.length > 0) {
                                        subAccountID = subActFind[0]["id"];
                                    }

                                    let beneficiaryList = [];
                                    let beneficiary_source = null;
                                    let beneficiary_id = null;
                                    if (beneficiaryD === "Area") {
                                        beneficiary_source = 7;
                                        beneficiaryList = beneficiaryData.area;
                                        // console.log(beneficiaryList);
                                        const ar = beneficiaryList.filter((item) => item.id === parseInt(refID));
                                        // console.log(ar);
                                        if (ar.length > 0) {
                                            beneficiary_id = ar[0]["id"];
                                        }
                                    } else if (beneficiaryD === "Staff") {
                                        beneficiary_source = 8;
                                        beneficiaryList = employee;
                                        // console.log(beneficiaryData);
                                        const ar = beneficiaryList.filter((item) => item.employee_id === (refID));
                                        if (ar.length > 0) {
                                            beneficiary_id = ar[0]["id"];
                                        }
                                    }


                                    const item = {
                                        account_id: accountID,
                                        sub_account_id: subAccountID,
                                        description: description,
                                        beneficiary_source: beneficiary_source,
                                        beneficiary_id: beneficiary_id,
                                        payment_method: 2,
                                        bank_account_id: null,
                                        check_no: null,
                                        checkno: "",
                                        amount: dt["amount"],
                                        files: [],
                                        file_location: [],
                                        subaccounts: subAccountList,
                                        beneficiary: beneficiaryList
                                    };

                                    newObject.push(item);
                                }
                                else {
                                    message.error('MainAccount column should not empty');
                                }

                            }
                        );

                        // console.log(newObject);

                        setState({rows: newObject})

                        newObject.map((item, index) => {
                            debitT += item.amount;
                        });

                        setBill({
                            totalReceipt: debitT
                        });
                    } else {
                        message.error('Data Validation check error. 200 rows allowed at a time.');
                    }

                    // if(newArray[0]["beneficiary"] === 'Area') {
                    //
                    // }
                    // else {
                    //     message.error('Invalid Import File. Please import using the template.');
                    // }

                    document.getElementById("loader").style.display = "none";

                }
            } else {
                message.error('You can only upload XlSX(Only Excel File) formatted file!');
                document.getElementById("loader").style.display = "none";
            }

        }

    }

    return (
        <Card title={<IntlMessages id={"Multiple Voucher Entry"} />} extra={
            <>
                {
                    isEdit === 1
                        ? CisUI().addAction('../../' + jsonfile.urls.list)
                        : CisUI().addAction('../' + jsonfile.urls.list)
                }
            </>
        }>
            {CisUI().showLoading}

            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                ref={formRef}
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{remember: true}}
            >
                {/*{*/}
                {/*    isLoaded === 1 ? <>*/}
                {/*        {*/}
                {/*            formField*/}
                {/*        }*/}
                {/*        <div onLoad={OrderDetails}></div>*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-md-12" style={{textAlign: 'center', marginTop : '30px'}}>*/}
                {/*                <Button id="submitbutton" type="primary" htmlType="submit">*/}
                {/*                    Save*/}
                {/*                </Button>*/}
                {/*                <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>*/}
                {/*            </div>*/}
                {/*        </div>*/}

                {/*    </> : CisUI().showLoading1*/}
                {/*}*/}


                {
                    formField
                }
                <div onLoad={OrderDetails}></div>
                {
                    isLoaded === 1 ? <>
                        <div className="row">
                            <div className="col-md-12" style={{textAlign: 'center', marginTop : '30px'}}>
                                <Button id="submitbutton" type="primary" htmlType="submit">
                                    Save
                                </Button>
                                <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                            </div>
                        </div>
                    </> : CisUI().showLoading1
                }

            </Form>

            <Modal title="Excel Import" open={isModalOpen} onOk={handleOk} okText={"Done"} onCancel={handleCancel}>
                <p>
                    <div id="msg"></div>
                   <div className="row">
                       <div className="col-md-12">

                           {/*<Upload{...uplod}>*/}
                           {/*    <Button icon={<UploadOutlined />}>Click to Upload</Button>*/}
                           {/*</Upload>*/}
                           <div style={{fontWeight: 'bold', paddingBottom: '10px'}}>Choose / Load Excel File</div>
                           <input type="file" id="file" onChange={onChangeExcel} />
                       </div>
                   </div>
                </p>
            </Modal>

        </Card>
    );
};

export default MultipleVoucherNew;