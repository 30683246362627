import React, {useState, useEffect} from "react";
import { Form, Col, Input, Table, Collapse ,Card, notification} from 'antd';
import jsonfile from './ledger.json'
import {Link, useHistory} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import axios from "../../../../util/Api";

const Panel = Collapse.Panel;
const { Column } = Table;

const BankAccountSummary = (props) => {
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const accountID = params.get("account_id") || 0;
    const date = params.get("date") || "";
    let url = Config.apiserver+jsonfile.urls.banksummary;
    if(accountID > 0) {
        url = Config.apiserver+jsonfile.urls.banksummary+"?account_id="+accountID+"&date="+date;
    }

    const [state,setState] = useState({
        reqURL: url,
        filtervalue : '',
        subtitle : '',
    });

    const [hide,setHide] = useState([]);

    const ref = React.createRef();
    const formRef = React.createRef();

    const [data,setData] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    useEffect(() => {
        if(CisUI().getUserInfo('com_id') > 0) {
            getLedgerData();
        }
    }, []);

    const getLedgerData = (url = "") => {
        document.getElementById("loader").style.display = "block";
        let urls = state.reqURL;
        if(url !== "") {
            urls = url;
        }

        axios.get(urls)
            .then((res) => {
                console.log(res)
                if (res.data.status === 1) {
                    setData(res.data.data);
                } else {
                    setData([])
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
                document.getElementById("loader").style.display = "none";
            })
            .finally(() => {
                setIsLoading(false);
            });

    }

    const getDetailsLink = (source,id, source2=null) => {

        if(source === 'bill') {
            return "../../"+jsonfile.detaillink.bill+"/"+id;
        }
        else if(source === 'journal') {
            return "../../"+jsonfile.detaillink.journal+"/"+id;
        }
        else if(source === 'ledger') {
            return "../../"+jsonfile.detaillink.ledgerdetails+"/"+id;
        }
        else if(source === 'invoice') {
            return "../../"+jsonfile.detaillink.invoice+"/"+id;
        }
        else if(source === 'creditnote') {
            return "../../"+jsonfile.detaillink.creditnote+"/"+id;
        }
        else if(source === 'voucher') {
            return "../../"+jsonfile.detaillink.voucherdetails+"/"+id;
        }
        else if(source === 'transaction') {
            if(source2 === 'Invoice payment collect') {
                return "../../" + jsonfile.detaillink.salesinvoice + "/" + id;
            }
            else {
                return "../../" + jsonfile.detaillink.transactiondetails + "/" + id;
            }
        }
        else {
            return "../../"+jsonfile.detaillink.journal+"/"+id;
        }
    }

    const history = useHistory();

    const headers = {
        // _id: {
        //     text: '#',
        //     invisible: false,
        //     filterable: false,
        //     transform: (value) => `${++value}`,
        // },
        id: {
            invisible : true
        },
        date: {
            text: <IntlMessages id="Date"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().DateFormat(value)
            )
        },
        debit: {
            text: <IntlMessages id="Debit"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        credit: {
            text: <IntlMessages id="Credit"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        }
    };

    const orderedHeaders = jsonfile.listView.title;

    const getFields = () => {
        const count = expand ? 10 : 6;
        const children = [];
        const inputs = jsonfile.input;

        inputs.map((list, index) => {
            if(list.isFilterable == 1) {
                children.push(
                    <Col lg={6} md={6} sm={12} xs={24} key={index} style={{display: index < count ? 'block' : 'none'}}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name={`${list.name}`}
                                label={`${list.label}`}
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <Input placeholder={list.name} />
                            </Form.Item>
                        </div>
                    </Col>,
                );
            }
        });
        return children;
    };

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        let wkd = "";
        let wk = "";
        let dt = "";
        const qs = Object.keys(values).map(key => {
                if (key === 'date') {
                    wk = formRef.current.getFieldValue('date');
                    const dt = CisUI().ParseDateFormat(values[key]);
                    return `${key}=${dt}`
                } else if (key === 'month') {
                    wk = formRef.current.getFieldValue('month');
                    // console.log("Selected Week : "+ wk);
                    wkd = CisUI().getMonthTitle(formRef.current.getFieldValue('month'));
                    return `${key}=${wkd[1]}`
                } else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        const qsup = qs.replaceAll("undefined", "");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?" + qsup);
        const newURL = Config.apiserver + jsonfile.urls.banksummary + "?" + qsup;
        setData([]);
        setState({reqURL: newURL});

        getLedgerData(newURL);
    };



    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        ref={formRef}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.search,"",1,"",CisUI().PeriodFiled(form))}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {/*<Search*/}
            {/*    placeholder='Filter...'*/}
            {/*    name='filtervalue'*/}
            {/*    onSearch={handleOnFilter}*/}
            {/*    onChange={handleOnFilter}*/}
            {/*    style={{width: 200}}*/}
            {/*/>*/}

            {CisUI().listActionReportXLS("../"+jsonfile.urls.add,"",state.reqURL,"data","general_ledger",jsonfile,"Journal", "landscape")}
        </>
    );

    const setCollapse = (id) => {
        console.log("Slcct: ", id);
        if(hide.indexOf(id) > -1) {
            var index = hide.indexOf(id); // Let's say it's Bob.
            hide.splice(index,1);
            document.getElementById("icon_"+id).setAttribute("class","fas fa-chevron-down");
            var row = document.getElementsByClassName("disp_"+id);
            var i;
            for (i = 0; i < row.length; i++) {
                //row[i].style.display = 'inherit';
                row[i].removeAttribute('style');
            }
        }
        else {
            const hd = hide;
            hd.push(id);
            setHide(hd);
            document.getElementById("icon_"+id).setAttribute("class","fas fa-chevron-right")
            var row = document.getElementsByClassName("disp_"+id);
            var i;
            for (i = 0; i < row.length; i++) {
                row[i].style.display = 'none';
            }
        }
        console.log("hides : ", hide);
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            console.log(selected, selectedRows, changeRows);
        },
    };


    const columns = [
        // {
        //     title: 'Account',
        //     dataIndex: 'account',
        //     key: 'account',
        //     className : "text-bold",
        //     render: (account,record) => (
        //         <Link to={"actledger/"+record.id}>{account}</Link>
        //     ),
        // },
        {
            title: 'Account/Date',
            dataIndex: 'date',
            key: 'date',
            render: (date,record) => (
                <>{record.type === 'entry' ? CisUI().DateFormat(date) : record.type === 'bb' ? <Link to={"bankactdetails?account_id="+record.id} style={{fontWeight: 'bold'}}>{record.account}</Link> : <></>}</>
            ),
        },
        {
            title: 'Type',
            dataIndex: 'source',
            key: 'source',
            render: (source,record) => (
                <>
                    {
                        record.link_id === 0 ? <span style={{fontWeight: 'bold'}}>{source}</span> : <Link to={getDetailsLink(record.link_to,record.link_id,record.source)}>{source}</Link>
                    }
                </>
            ),
        },
        {
            title: 'Beneficiary',
            dataIndex: 'beneficiary',
            key: 'beneficiary',
        },
        {
            title: 'Description/Memo',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Ref. No',
            dataIndex: 'ref_no',
            key: 'ref_no',
        },
        {
            title: 'Check#',
            dataIndex: 'trx_ref',
            key: 'trx_ref',
        },
        {
            title: 'Inflow',
            dataIndex: 'debit',
            key: 'debit',
            align: 'right',
            width: '150px',
            render: (debit,record) => (
                <>
                    {
                        record.link_id === 0 ? <span style={{fontWeight: 'bold'}}>{CisUI().getCurrencyFormated1(debit)}</span> : <Link to={getDetailsLink(record.link_to,record.link_id,record.source)}>{(record.type === "entry" || record.type === "end") ? CisUI().getCurrencyFormated1(debit) : ""}</Link>
                    }
                </>
            ),
        },
        {
            title: 'Outflow',
            dataIndex: 'credit',
            key: 'credit',
            align: 'right',
            width: '150px',
            render: (credit,record) => (
                <>
                    {
                        record.link_id === 0 ? <span style={{fontWeight: 'bold'}}>{CisUI().getCurrencyFormated1(credit)}</span> : <Link to={getDetailsLink(record.link_to,record.link_id,record.source)}>{(record.type === "entry" || record.type === "end") ? CisUI().getCurrencyFormated1(credit) : ""}</Link>
                    }
                </>
            ),
        },
        {
            title: 'Current Balance',
            dataIndex: 'current_balance',
            key: 'current_balance',
            align: 'right',
            width: '150px',
            // className: (vl,record) => (
            //     <>{(record.type === 'bb' || record.type === 'end') ? boldtext : normaltext}</>
            // ),
            render: (source,record) => (
                <>
                    {
                        record.link_id === 0 ? <span style={{fontWeight: 'bold'}}>{CisUI().getCurrencyFormated1(record.current_balance)}</span> : <Link to={getDetailsLink(record.link_to,record.link_id,record.source)}>{CisUI().getCurrencyFormated1(record.current_balance)}</Link>
                    }
                </>
            ),
        }
    ];

    const [checkStrictly, setCheckStrictly] = useState(false);

    return (
        <>
            {searchView}
            <Card title="Bank Account Summary" extra={getExtra}>
                {CisUI().showLoading}

                {data.length > 0 ? <Table
                    className="ledgerTable"
                    columns={columns}
                    // rowSelection={{ ...rowSelection, checkStrictly }}
                    pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '30', '50', '100','200','500']}}
                    dataSource={data}
                    scrollToFirstRowOnChange = {true}
                    expandable={{
                        defaultExpandAllRows: true
                    }}
                /> : ''}

            </Card>
        </>
    );
};

export default BankAccountSummary;