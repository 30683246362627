module.exports = {
  footerText: '©️ 2023 The Church of Pentecost – General Headquarters. All Rights Reserved',
  version: 'Version 2.0',
  currencySymbol : "¢",
  attachement: 'https://apiv2.penterp.com/public',
  apiserver: 'https://apiv2.penterp.com/api/',
  // baseurl: 'https://office.penterp.com/',
  baseurl: 'http://localhost:3000/',
  fileserver: 'https://apiv2.penterp.com/public',
  profile_pic_url: 'https://apiv2.penterp.com/public/upload/profile/',

  logourl : '/assets/images/penterpv1.png',
  // print_logourl : '/assets/images/penterpv1.png',
  print_logourl : '/assets/images/poc_logo.png',
  company_name : 'The Church of Pentecost HQ',
  address : 'The Church of Pentecost HQ P.O Box:  2034, Accra, Ghana',
  city : 'Wayne',
  state : 'New Jersey',
  zipcode : '11111',
  phone : "",
  email : "info@penterp.com",
  website : "https://penterp.com"
}
