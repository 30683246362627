import React, {useState} from "react";
import {Form, Card, notification, Col, Select, Row, Checkbox, Input} from 'antd';
import jsonfile from './asset_distribution.json'
import {CisUI} from '../../../../util/CISUI'
import InputFields from '../../../../util/InputField'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "axios";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../../util/gerremotedata";
import SelectData from "../../../../util/selectdata";
import IntlMessages from "../../../../util/IntlMessages";

const { Option } = Select;

const NewAssetDistribution = (props) => {

    const userID = props.match.params.id;
    const [emp, setEmp] = useState(true);
    const [other, setOther] = useState(false);
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../"+jsonfile.urls.list;

    let companylist = SelectData('company/companylists');
    let employeelist = SelectData('user/userlist');


    if(isEdit === 1 ) {
        userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
        redirectto = "../../"+jsonfile.urls.list;
    }

    function onchange(e) {
        if(e.target.checked === true) {
            // document.getElementById("other").style.display = "block";
            // document.getElementById("emp").style.display = "none";
            setEmp(false);
            setOther(true);
        } else {
            setEmp(true);
            setOther(false);
            // document.getElementById("other").style.display = "none";
            // document.getElementById("emp").style.display = "block";
        }

    }


    const [form] = Form.useForm();
    const history = useHistory();

    const onFinish = (values) => {
        const data = {
            ...values,
            'distribution_date' : values['distribution_date'].format("YYYY-MM-DD"),
            'start_date' : values['start_date'].format("YYYY-MM-DD"),
            'end_date' : values['end_date'].format("YYYY-MM-DD")
        }
        // console.log(data);
        // console.log(values);
        document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, data, CisUI().HeaderRequest)
            .then(res => {
                console.log(res)
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type : "success",
                        description: res.data.msg
                    });
                    history.push(redirectto);
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
            });
    };

    return (
        <Card title={Title} extra={
                isEdit === 1
                    ? CisUI().addAction('../../'+jsonfile.urls.list)
                    : CisUI().addAction('../'+jsonfile.urls.list)
            }>
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                <Row gutter={24}>
                    <Col lg={8} md={8} sm={12} xs={24}>
                        <div id="com" className="gx-form-row0">
                            <Form.Item
                                name="com_id"
                                label={<IntlMessages id="Company"/>}
                                initialValue={userData.com_id}
                                rules={[
                                    {
                                        required: true,
                                        message: "Select a company"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select level of company"
                                    optionFilterProp="children"
                                    // onChange={onAreaChange}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {
                                        companylist.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>

                        </div>
                    </Col>
                    <Col lg={8} md={8} sm={12} xs={24}>
                        {emp && (
                        <div id="emp" className="gx-form-row0">
                            <Form.Item
                                name="employee_id"
                                label={<IntlMessages id="Employee"/>}
                                initialValue={userData.employee_id}
                                rules={[
                                    {
                                        required: false,
                                        message: "Select a employee"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select Employee"
                                    optionFilterProp="children"
                                    // onChange={onAreaChange}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {
                                        employeelist.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        )}
                        {other && (
                        <div id="other" className="gx-form-row0">
                            <Form.Item
                                name="other_name"
                                label={<IntlMessages id="Other"/>}
                                initialValue={userData.other}
                                rules={[
                                    {
                                        required: false,
                                        message: "Enter Other Name"
                                    },
                                ]}
                            >
                                <Input placeholder="Other Name"/>
                            </Form.Item>
                        </div>
                        )}
                    </Col>

                    <Col lg={8} md={8} sm={12} xs={24}>
                        <div id="emp" className="gx-form-row0">
                            <Form.Item
                                name="others"
                                label={<IntlMessages id="Or Other"/>}
                                initialValue={userData.employee_id}
                                rules={[
                                    {
                                        required: false,
                                        message: "Select a company"
                                    },
                                ]}
                            >
                                <Checkbox onChange={onchange}/>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                {InputFields(jsonfile.input,userData,isEdit,props)}
            </Form>
        </Card>
    );
};

export default NewAssetDistribution;