import React, {useState, } from "react";
import {Form, Upload, DatePicker, message, Button, Card, notification, Col} from 'antd';
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";

import { UploadOutlined } from '@ant-design/icons';
import * as XLXS from "xlsx";


const ImportJournal = (props) => {

    const [loading, setLoading] = useState([]);
    const [submitted, setSubmitted] = useState(0);

    const handleChange=(e)=> {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    // const [data, setData] = useState([]);
    const [total, setTotal] = useState({
        debit : 0,
        credit : 0
    });
    const [uploading, fileList] = useState([]);
    const [dataobject,setDataObject] = useState([]);

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    // let Title = jsonfile.addtitle;
    // let endpoint = jsonfile.urls.add;
    // let redirectto = "../"+jsonfile.urls.list;

    // if(isEdit === 1 ) {
    //     userData = RemoteJson(jsonfile.urls.view+"/"+userID);
    //     Title = jsonfile.edittitle;
    //     endpoint = jsonfile.urls.edit+"/"+userID;
    //     redirectto = "../../"+jsonfile.urls.list;
    // }

    let newArray = [];

    const onChangeExcel = (e) => {
        let file = e.target.files[0];
        setDataObject([]);
        let debitT = 0;
        let creditT = 0;
        if(file !== "") {
            if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/x-vnd.oasis.opendocument.spreadsheet') {
               // console.log(file);
                document.getElementById("loader").style.display = "block";
                let reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = (e) => {
                    const bufferArray = e.target.result;
                    const wb = XLXS.read(bufferArray, {type: 'buffer'});
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    const data1 = XLXS.utils.sheet_to_json(ws);
                    // console.log(data1);
                    // setData(data1);

                    const subsChars = o => Object.fromEntries(Object.entries(o).map(
                        ([k, v]) => [k.replace(/[ \W]/g, m => m === ' ' ? '_' : '').toLowerCase(), v]
                    ))
                    newArray = data1.map(subsChars)

                    let newObject = [];
                    newArray?.map((dt, index) => {

                            let allow = 0;
                            if ((dt.debit !== undefined)) {
                                allow = 1;
                            }

                            if ((dt.credit !== undefined)) {
                                allow = 1;
                            }

                            if (allow === 1) {
                                if (dt.account_number !== undefined) {
                                    //console.log(dt);
                                    let newd = {
                                        'account_number': dt.account_number || "",
                                        'account_name': dt.account_name || "",
                                        // 'fund_program': dt.fund_program || "",
                                        // 'functional_expenses': dt.functional_expenses || "",
                                        // 'restriction_type': dt.restriction_type || "",
                                        'debit': dt.debit || 0,
                                        'credit': dt.credit || 0,
                                    };

                                    newObject.push(newd);
                                }
                            }

                        }
                    );

                    newObject.map((item,index) => {
                        debitT += item.debit;
                        creditT += item.credit;
                    });

                    //console.log("AllData : " + JSON.stringify(newObject));
                    // console.log("credit : " + creditT);
                    setTotal({
                        debit: debitT.toFixed(2),
                        credit: creditT.toFixed(2),
                    });

                    if(debitT > 0) {
                        if(debitT.toFixed(2) === creditT.toFixed(2)) {
                            document.getElementById("msg").style.color = "green";
                            document.getElementById("msg").innerText = "";
                        }
                        else {
                            console.log("Debit "+ debitT.toFixed(2));
                            console.log("Credit "+ creditT.toFixed(2));
                            // console.log("Not Equal");
                            document.getElementById("msg").style.color = "red";
                            document.getElementById("msg").innerText = "Total debit and credit value is not same, please check in your file";
                        }
                    }
                    else {
                        if(newObject.length > 0) {
                            console.log("Not Worked")
                            document.getElementById("msg").style.color = "red";
                            document.getElementById("msg").innerText = "Total debit and credit value is not same, please check in your file";
                        }
                        else {
                            document.getElementById("msg").style.color = "red";
                            document.getElementById("msg").innerText = "No valid data found, please check your file";
                        }
                    }


                    setDataObject(newObject);

                    document.getElementById("loader").style.display = "none";

                }
            } else {
                setTotal({
                    debit: 0,
                    credit: 0,
                });
                message.error('You can only upload XlXS(Only Excel File) formated file!');
                document.getElementById("loader").style.display = "none";
            }

        }
    }


    const [form] = Form.useForm();
    const history = useHistory();

    const onFinish = (values) => {

        // let msg = "Sorry! request not processed, please try again";
        values["data"] = dataobject;
        if(total.debit > 0 && (total.debit === total.credit)) {
            document.getElementById("loader").style.display = "block";
            axios.post(Config.apiserver+"accounts/journalimport", values)
                .then(res => {
                    // console.log(res)
                    if (res.data.status === 1) {
                        notification.success({
                            message: 'Success',
                            type: "success",
                            description: res.data.msg
                        });
                        setSubmitted(1);
                        setDataObject(res.data.result);
                        document.getElementById("msg").style.color = "green";
                        document.getElementById("msg").innerText = res.data.msg;
                    }
                    else if (res.data.status === 3) {
                        notification.success({
                            message: 'Success',
                            type: "success",
                            description: res.data.msg
                        });
                        setSubmitted(1);
                        setDataObject(res.data.result);
                        document.getElementById("msg").style.color = "red";
                        document.getElementById("msg").innerText = res.data.msg;
                    } else {
                        notification.warning({
                            message: 'Alert',
                            type: "warning",
                            description: res.data.msg
                        });
                        document.getElementById("msg").style.color = "green";
                        document.getElementById("msg").innerText = res.data.msg;
                    }
                    document.getElementById("loader").style.display = "none";
                })
                .catch(errors => {
                    document.getElementById("loader").style.display = "none";
                    console.log(errors);
                });
        }
        else {
            notification.warning({
                message: 'Alert',
                type: "warning",
                description: "Total debit and credit are not same, please check your file."
            });
        }
    };


    const handleUpload = () => {
        const { fileList } = this.state;
        const formData = new FormData();
        fileList.forEach(file => {
            formData.append('files[]', file);
        });
        this.setState({
            uploading: true,
        });
        // You can use any AJAX library you like
        fetch('https://www.mocky.io/v2/5cc8019d300000980a055e76', {
            method: 'POST',
            body: formData,
        })
            .then(res => res.json())
            .then(() => {
                this.setState({
                    fileList: [],
                });
                message.success('upload successfully.');
            })
            .catch(() => {
                message.error('upload failed.');
            })
            .finally(() => {
                this.setState({
                    uploading: false,
                });
            });
    };

    const upld = {
        onRemove: file => {
            this.setState(state => {
                const index = state.fileList.indexOf(file);
                const newFileList = state.fileList.slice();
                newFileList.splice(index, 1);
                return {
                    fileList: newFileList,
                };
            });
        },
        beforeUpload: file => {
            this.setState(state => ({
                fileList: [...state.fileList, file],
            }));
            return false;
        },
        fileList,
    };

    const token = localStorage.getItem('token');
    
    
    
    // const formData = new FormData();
    // const uplod = {
    //     // name: 'file',
    //     // action: Config.apiserver+"settings/openingbalance",
    //     // headers: {
    //     //     'Authorization': token,
    //     // },
    //     // data : {
    //     //   'file' : formData
    //     // },
    //     beforeUpload(file) {
    //         console.log(file)
    //         // let file = e.target.files[0];
    //         let reader = new FileReader();
    //         reader.readAsArrayBuffer(file);
    //
    //         reader.onload = (e) => {
    //             const bufferArray = e.target.result;
    //             const wb = XLXS.read(bufferArray, {type:'buffer'});
    //             const wsname = wb.SheetNames[0];
    //             const ws = wb.Sheets[wsname]
    //             const data = XLXS.utils.sheet_to_json(ws)
    //
    //             // console.log(data)
    //
    //             // resolve(data)
    //
    //             setData(data)
    //         }
    //         // const isJpgOrPng = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/x-vnd.oasis.opendocument.spreadsheet';
    //         // if (!isJpgOrPng) {
    //         //     message.error('You can only upload XlXS(Only Excel File) formated file!');
    //         // }
    //         // return isJpgOrPng;
    //     },
    //     onChange(info) {
    //         formData.append('file', info.file)
    //         if (info.file.status !== 'uploading') {
    //             console.log(info.file, info.fileList);
    //         }
    //         if (info.file.status === 'done') {
    //             message.success(`${info.file.name} file uploaded successfully`);
    //         } else if (info.file.status === 'error') {
    //             message.error(`${info.file.name} file upload failed.`);
    //         }
    //     }
    // }

    // console.log("Data: ", data);
    
    


   // console.table(data);
    // console.log("Obj: " ,dataObj)
    

    const getRemark = (text) => {
        if(text === "Success") {
            return <span style={{color: 'green'}}>{text}</span>;
        }
        return text;
    }

    return (
        <Card title={"Import Journal"}>
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{ remember: true }}
            >
                <div className="row">
                    <div className="col-md-12">
                        <h3>INSTRUCTIONS :</h3>
                        <div>
                            {
                                CisUI().getUserInfo('comarea') === 3 ?
                                    <>
                                        1. Download Sample file : <a style={{fontWeight: 'bold'}} href="https://api.penterp.com/public/upload/tempfile/NationaTemplate.xlsx" target="_blank">Click to Download</a><br />
                                    </>
                                    :""
                            }
                            {
                                CisUI().getUserInfo('comarea') === 4 ?
                                    <>
                                        1. Download Sample file : <a style={{fontWeight: 'bold'}} href="https://api.penterp.com/public/upload/tempfile/regiontemplate.xlsx" target="_blank">Click to Download</a><br />
                                    </>
                                :""
                            }
                            {
                                CisUI().getUserInfo('comarea') === 5 ?
                                    <>
                                        1. Download Sample file : <a style={{fontWeight: 'bold'}} href="https://api.penterp.com/public/upload/tempfile/districttemplate.xlsx" target="_blank">Click to Download</a> <br />
                                    </>
                                    :""
                            }
                            {
                                CisUI().getUserInfo('comarea') === 6 ?
                                    <>
                                        1. Download Sample file : <a style={{fontWeight: 'bold'}} href="https://api.penterp.com/public/upload/tempfile/localtemplate.xlsx" target="_blank">Click to Download</a> <br />
                                    </>
                                    :""
                            }
                        </div>
                        <div>
                            2. Enter your debit or credit balance on excel file <br />
                            3. Verify that the total debit and total credit are the same.<br />
                            4. Once done, use the “Click to Upload” option to load. <br />

                            <div className="row" style={{margin: '20px'}}>
                                <div className="col-md-4" style={{display: "none"}}>
                                    <Form.Item
                                        name="date"
                                        label="Date"
                                        rules={[
                                            {
                                                required: false,
                                                message: "Select a date"
                                            },
                                        ]}
                                    >
                                        <DatePicker format={CisUI().dateFormat} className="gx-mb-3 gx-w-100"
                                                    placeholder="Date"/>
                                    </Form.Item>
                                </div>
                                <div className="col-md-4">

                                    {/*<Upload{...uplod}>*/}
                                    {/*    <Button icon={<UploadOutlined />}>Click to Upload</Button>*/}
                                    {/*</Upload>*/}
                                    <div style={{fontWeight: 'bold', paddingBottom: '10px'}}>Choose your Excel File</div>
                                    <input type="file" onChange={onChangeExcel}/>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12" style={{textAlign : 'center'}}>
                                    <h3 style={{color: 'red'}} id="msg"></h3>
                                </div>
                            </div>

                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Account Code</th>
                                        <th>Account Name</th>
                                        {/*<th>Fund Program</th>*/}
                                        {/*<th>Functional Expense</th>*/}
                                        {/*<th>Restriction Type</th>*/}
                                        <th style={{textAlign : 'right'}}>Debit</th>
                                        <th style={{textAlign : 'right'}}>Credit</th>
                                        {
                                            submitted === 1 ? <th>Result</th> : ""
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                {/*<tr>*/}
                                {/*    <td>10010</td>*/}
                                {/*    <td>Checking Account</td>*/}
                                {/*    <td>Opening Balance</td>*/}
                                {/*    <td>Tithes & Offering</td>*/}
                                {/*    <td>Program Servies</td>*/}
                                {/*    <td>without</td>*/}
                                {/*    <td>10000</td>*/}
                                {/*    <td>0</td>*/}
                                {/*</tr>*/}
                                {
                                    dataobject.map((dt, index) =>
                                        <tr>
                                            <td>{dt.account_number}</td>
                                            <td>{dt.account_name}</td>
                                            {/*<td>{dt.fund_program}</td>*/}
                                            {/*<td>{dt.functional_expenses}</td>*/}
                                            {/*<td>{dt.restriction_type}</td>*/}
                                            <td style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(dt.debit ?? 0)}</td>
                                            <td style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(dt.credit ?? 0)}</td>
                                            {
                                                submitted === 1 ? <th style={{paddingLeft : '10px'}}>{getRemark(dt.remark)}</th> : ""
                                            }
                                        </tr>
                                    )
                                }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan={2}>Total</th>
                                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(total.debit)}</th>
                                        <th style={{textAlign : 'right'}}>{CisUI().getCurrencyFormated1(total.credit)}</th>
                                        {
                                            submitted === 1 ? <th></th> : ""
                                        }
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                </div>
                <Col lg={24} xs={24} style={{ textAlign: "center" }}>
                    {
                        total.debit > 0 ? total.debit === total.credit ? submitted === 0 ?
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button> : "" : "" : ""
                    }
                </Col>
            </Form>
        </Card>
    );
};

export default ImportJournal;