import {Form, Input, Button, Card, Modal, Select, notification, Row, Col, DatePicker} from 'antd';
import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import jsonfile from './sales.json'
import {CisUI} from '../../../../util/CISUI'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import {useHistory} from "react-router-dom";
import moment from "moment";


var Barcode = require('react-barcode');

const ReachableContext = React.createContext();
const UnreachableContext = React.createContext();

const {Option} = Select;
const {TextArea} = Input;

const SalesOrderDetails = (props) => {

    const formRef = React.createRef();
    const [paymentmethod, setPaymentmethod] = useState([]);
    const [bankaccounts, setBankAccounts] = useState([]);
    const [cashaccount, setCashAccount] = useState([]);
    const [payments, setPayment] = useState([]);
    const [incomes, setIncomes] = useState([]);
    const [isPaid, setIsPaid] = useState(false);
    const [form] = Form.useForm();


    const [state, setState] = useState({
        po: {},
        items: [],
        isPOApprover: 0,
        hasPayment: 0
    })


    const userID = props.match.params.id;

    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }

    const print = () => {
        window.print();
    };

    const printReceipt = () => {
        const url = Config.apiserver + jsonfile.urls.view +"/"+userID;
        window.open("../../receipt.html?type=checkprint&url=" + window.btoa(url), "_blank");
    }

    const onFinish = () => {

    }

    let Title = jsonfile.details_title;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../" + jsonfile.urls.list;

    if (isEdit === 1) {

        Title = jsonfile.details_title;
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../" + jsonfile.urls.list;
    }

    useEffect(() => {
        getPODetails();
    }, []);

    const getPODetails = () => {
        axios.get(Config.apiserver + jsonfile.urls.view + "/" + userID)
            .then((res) => {
                //console.log(res.data);
                if (res.data.status === 1) {
                    setState({
                        po: res.data.data,
                        items: res.data.items,
                        hasPayment: res.data.hasPayment
                    });
                    setCashAccount(res.data.cashAccount);
                    setPayment(res.data.payment);
                    setBankAccounts(res.data.bankaccount);
                    setIncomes(res.data.incomes);
                    setPaymentmethod(res.data.payment_method);

                    const total = res.data.data.grand_total || 0;
                    const totalPaid = res.data.data.paid_amount || 0;
                    if(total.toFixed(2) === totalPaid.toFixed(2)) {
                        setIsPaid(true);
                    }
                    else {
                        setIsPaid(false);
                    }

                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const history = useHistory();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
    const [isPaymentVisible, setPaymentVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showPaymentModal = () => {
        setPaymentVisible(true);
    };

    const handlePaymentOk = () => {
        setPaymentVisible(false);
    };

    const handlePaymentCancel = () => {
        setPaymentVisible(false);
    };

    const showModalCofirm = () => {
        setIsConfirmModalVisible(true);
    };

    const handleOkConfirm = () => {
        setIsConfirmModalVisible(false);
        PoStatusUpdate(state.po.id,1);
    };

    const handleCancelConfirm = () => {
        setIsConfirmModalVisible(false);
    };

    const showModalReject = () => {
        setIsRejectModalVisible(true);
    };

    const handleOkReject = () => {
        setIsRejectModalVisible(false);
        PoStatusUpdate(state.po.id,3);
    };

    const handleCancelReject = () => {
        setIsRejectModalVisible(false);
    };

    const handleChange = () => {

    }



    const PoStatusUpdate = (id,status) => {
        document.getElementById("loader").style.display = "block";
        axios.get("purchase/postatuschange?po_id="+id+"&status="+status)
            .then((res) => {
                document.getElementById("loader").style.display = "none";
                console.log(res.data);
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });
                    getPODetails();
                }
                else {
                    document.getElementById("loader").style.display = "none";
                    notification.success({
                        message: 'Failed',
                        type: "danger",
                        description: res.data.msg
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    }

    var totalAmount = 0;

    const config = {
        title: 'Alert',
        content: (
            <>
                <ReachableContext.Consumer>{name => `Reachable: ${name}!`}</ReachableContext.Consumer>
                <br />
                <UnreachableContext.Consumer>{name => `Unreachable: ${name}!`}</UnreachableContext.Consumer>
            </>
        ),
    };

    const onPaymentMethodChange = (value) => {
        console.log("mehtod" + value);
        if(value === 2) {
            document.getElementById("bankaccount").style.display = "block";
        }
        else {
            document.getElementById("bankaccount").style.display = "none";
        }
        form.setFieldsValue({
            bank_account_id : null,
            reference : ""
        });
    }

    const onPaymentReceipt = (values) => {
        document.getElementById("loader").style.display = "block";
        axios.post("entries/paymentreceipt/"+userID,values)
            .then((res) => {
                document.getElementById("loader").style.display = "none";
                console.log(res.data);
                if(res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });

                    setIsPaid(true);

                    //getPODetails();
                    handlePaymentCancel();
                    window.location.reload();
                }
                else {
                    document.getElementById("loader").style.display = "none";
                    notification.success({
                        message: 'Failed',
                        type: "danger",
                        description: res.data.msg
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    }


    return (
        <>
            <Card title={Title} extra={
                isEdit === 1
                    ? CisUI().addAction('../../' + jsonfile.urls.list)
                    : CisUI().addAction('../' + jsonfile.urls.list)
            }>
                {CisUI().showLoading}

                <div className="row">
                    <div className="col-md-12" style={{textAlign: 'center'}}>
                        <div style={{width: '100%', background: 'white', border: '1px #ddd solid', padding: '20px'}}>

                            <table style={{width: '100%'}}>
                                <tr>
                                    <td style={{width: '50%', textAlign: 'left'}}>
                                        <img alt="cop" style={{width: '100px'}} src={Config.print_logourl}/>
                                        {/*<div>{Config.address}</div>*/}
                                        {/*<div>{Config.city},{Config.state},{Config.zipcode}</div>*/}
                                        {/*<div>Phone : {Config.phone}</div>*/}
                                        {/*<div>Email : {Config.email}</div>*/}
                                        {/*<div>Website : {Config.website}</div>*/}
                                    </td>
                                    <td style={{width: '50%', textAlign: 'right'}}>
                                        <div style={{textAlign: "right", width: '250px', float: 'right'}}>
                                            <h2>Sales Invoice</h2>
                                            <div>Date : {CisUI().DateFormat(state.po.date)}</div>
                                            <div>Invoice Number : {state.po.invoice_number}</div>
                                            <div><Barcode height={50} value={state.po.invoice_number}/></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width: '50%', textAlign: 'left', paddingTop: '20px'}}>
                                        <h3>Company Information : </h3>
                                        <h5>{state.po.company}</h5>
                                        <div>{state.po.com_address}</div>
                                        <div>{state.po.com_city}, {state.po.com_state}, {state.po.com_zipcode}</div>
                                        <div>Phone : {state.po.company_phone}</div>
                                        <div>Email : {state.po.company_email}</div>
                                    </td>
                                    <td style={{width: '50%', textAlign: 'right', paddingTop: '20px'}}>
                                        <div style={{textAlign: "left", width: '250px', float: 'right'}}>
                                            <h3>Customer Information : </h3>
                                            <h5>{state.po.customer_name}</h5>
                                            <div>{state.po.customer_address}</div>
                                            <div>{state.po.customer_city}, {state.po.customer_state}, {state.po.customer_zipcode}</div>
                                            <div>Phone : {state.po.customer_phone}</div>
                                            <div>Email : {state.po.customer_email}</div>
                                        </div>
                                    </td>
                                </tr>
                            </table>

                            <table className="ptable table table-bordered" style={{marginTop: '30px'}} width="100%">
                                <thead>
                                <tr>
                                    <th>Required By</th>
                                    <th>Shipping Method</th>
                                    <th>Payment Terms</th>
                                    <th>Discount(%)</th>
                                    <th>Sales Tax(%)</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{CisUI().DateFormat(state.po.required_by_date)}</td>
                                    <td>{state.po.shipping_method}</td>
                                    <td>{state.po.payment_terms}</td>
                                    <td>{state.po.discount_p}%</td>
                                    <td>{state.po.sales_tax_p}%</td>
                                </tr>
                                </tbody>
                            </table>

                            <table id="itemtable" style={{marginTop: '30px'}} className="ptable table table-bordered">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Item Code/Sku</th>
                                    <th>Item Description</th>
                                    <th>Quantity</th>
                                    <th>Delivered</th>
                                    <th>Unit Price($)</th>
                                    <th>Amount</th>
                                </tr>
                                </thead>
                                <tbody>
                                {state.items.map((item, idx) => (
                                    <tr id="addr0" key={idx}>
                                        <td style={{width: '30px'}}>{(idx + 1)}</td>
                                        <td style={{width: '150px',textAlign: 'left'}}>{item.sku}</td>
                                        <td style={{textAlign: 'left'}}>{item.name}</td>
                                        <td style={{width: '70px', textAlign: 'right'}}>{item.quantity}</td>
                                        <td style={{width: '70px', textAlign: 'right'}}>{item.delivered_quantity}</td>
                                        <td style={{
                                            width: '70px',
                                            textAlign: 'right'
                                        }}>{CisUI().getCurrencyFormated1(item.price)}</td>
                                        <td style={{
                                            width: '70px',
                                            textAlign: 'right'
                                        }}>{CisUI().getCurrencyFormated1((item.price * item.quantity))}</td>
                                    </tr>
                                ))}
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td colSpan={3} rowSpan={8} style={{verticalAlign: "top"}}></td>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}} colSpan={2}>Gross Total</td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(state.po.total)}</td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: 'right'}} colSpan={3}>Discount</td>
                                    <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.po.discount)}</td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}} colSpan={3}>After Discount</td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1((parseFloat(state.po.total) - parseFloat(state.po.discount)))}</td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: 'right'}} colSpan={3}>Sales Tax</td>
                                    <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.po.sales_tax)}</td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: 'right'}} colSpan={3}>Freight</td>
                                    <td style={{textAlign: 'right'}}>{CisUI().getCurrencyFormated1(state.po.freight)}</td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}} colSpan={3}>Grand Total</td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(state.po.grand_total)}</td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}} colSpan={3}>Amount Paid</td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1(state.po.paid_amount)}</td>
                                </tr>
                                <tr>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}} colSpan={3}>Amount Due</td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold'
                                    }}>{CisUI().getCurrencyFormated1((state.po.grand_total - state.po.paid_amount))}</td>
                                </tr>
                                </tfoot>
                            </table>

                        </div>
                    </div>
                </div>

                <div className="row no-print">
                    <div className="col-md-12" style={{textAlign: 'center', marginTop: '30px'}}>

                        <Button id="submitbutton" onClick={printReceipt} type="primary" htmlType="button">Print Receipt</Button>
                        <Button id="submitbutton" onClick={print} type="primary" htmlType="button">Print</Button>
                        {/*<Button id="submitbutton" type="primary" htmlType="button">Send to Customer</Button>*/}
                        {/*<Button id="submitbutton" onClick={showModal} type="primary" htmlType="button">Send to Anyone</Button>*/}
                        {
                            isPaid ? <></> :
                                <>
                                    <Button id="submitbutton" type="primary" onClick={showPaymentModal} htmlType="button">
                                        Receive Payment
                                    </Button>
                                </>
                        }
                        <a className="ant-btn ant-alert-info" onClick={() => history.goBack()} htmlType="back">Back</a>
                    </div>
                </div>
            </Card>
            <Modal title="Send To Anyone" visible={isModalVisible} okText={"Send"} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    form={form}
                    {...CisUI().formItemLayout}
                    name="input"
                    ref={formRef}
                    className="ant-advanced-search-form"
                    onFinish={onFinish}>

                    <div className="row">
                        <div className="col-md-12">
                            <Form.Item
                                label="Email Address"
                                name="email"
                                onChange={handleChange}
                                rules={[
                                    {
                                        required: true,
                                        message: "Enter email addres, separate by comma for multiple email "
                                    },
                                ]}
                            >
                                <Input placeholder="Enter email address" />
                            </Form.Item>
                        </div>
                    </div>

                </Form>
            </Modal>

            <Modal title="Alert" visible={isConfirmModalVisible} okText={"Confirm"} onOk={handleOkConfirm} onCancel={handleCancelConfirm}>
                <h4>Are you sure you want to approve ?</h4>
            </Modal>

            <Modal title="Alert" visible={isRejectModalVisible} okText={"Confirm"} onOk={handleOkReject} onCancel={handleCancelReject}>
                <h4>Are you sure you want to reject ?</h4>
            </Modal>

            <Modal title="Payment Receive" visible={isPaymentVisible} okButtonProps={{ form: 'paymentForm', key: 'submit', htmlType: 'submit' }} okText="Submit" onCancel={handlePaymentCancel} width={800}>
                <Form id="paymentForm" form={form} {...CisUI().formItemLayout} onFinish={onPaymentReceipt} >
                    <Row className="ant-row" style={{ rowGap: "15px" }} justify="space-between">

                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Total Amount</label>
                                <input type="text" className="form-control" readOnly={true} value={CisUI().getCurrencyFormated1(state.po.grand_total)} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <Form.Item
                                name="date"
                                label="Date"
                                initialValue={moment()}
                                rules={[
                                    {
                                        required: true,
                                        message: "Select a date"
                                    },
                                ]}
                            >
                                <DatePicker format={CisUI().dateFormat} className="gx-mb-3 gx-w-100"
                                            placeholder="Date"/>
                            </Form.Item>
                        </div>
                        <div className="col-md-6">
                            <Form.Item
                                name="payment_method"
                                label="Payment Method"
                                initialValue={1}
                                rules={[
                                    {
                                        required: true,
                                        message: "Select payment method"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select a payable account"
                                    optionFilterProp="children"
                                    onChange={onPaymentMethodChange}
                                    allowClear={true}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {
                                        paymentmethod.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="col-md-6" id="bankaccount" style={{display: 'none'}}>
                            <Form.Item
                                name="bank_account_id"
                                label="Bank Account"
                                rules={[
                                    {
                                        required: false,
                                        message: "Select a bank account"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select a bank account"
                                    optionFilterProp="children"
                                    allowClear={true}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {
                                        bankaccounts.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.account_no}-{items.account_name}({items.bank_name})</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="col-md-6">
                            <Form.Item
                                name="income_id"
                                label="Income Account"
                                rules={[
                                    {
                                        required: true,
                                        message: "Select an income account"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select an income account"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {
                                        incomes.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="col-md-6">
                            <Form.Item
                                name="account_id"
                                label="Account"
                                rules={[
                                    {
                                        required: true,
                                        message: "Select an account"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select an account"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {
                                        cashaccount.map((items, index) =>
                                            <Option key={++index} value={items.id}>{items.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="col-md-6">
                            <Form.Item
                                name="reference_no"
                                label="Reference#"
                                rules={[
                                    {
                                        required: false,
                                        message: "Enter reference number"
                                    },
                                ]}
                            >
                                <Input className="gx-mb-3 gx-w-100" placeholder="Enter a reference number"/>
                            </Form.Item>
                        </div>


                        <div className="col-md-6">
                            <Form.Item
                                name="amount"
                                label="Amount Receipt"
                                initialValue={state.po.grand_total}
                                rules={[
                                    {
                                        required: true,
                                        message: "Enter amount here"
                                    },
                                ]}
                            >
                                <Input placeholder="Enter amount ehre"/>
                            </Form.Item>
                        </div>

                    </Row>
                </Form>
            </Modal>


        </>
    );
};

export default SalesOrderDetails;