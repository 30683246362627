import React, {useState, useEffect} from "react";
import { Form, Col, Input, Table, Collapse ,Card, notification} from 'antd';
import jsonfile from './ledger.json'
import {Link, useHistory} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import axios from "../../../../util/Api";

const Panel = Collapse.Panel;
const { Column } = Table;

const CashflowStatementDetails = (props) => {
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const accountID = params.get("account_id") || 0;
    const date = params.get("date") || "";
    let url = Config.apiserver+jsonfile.urls.cashflowstmnt+"?tp=1";
    if(accountID > 0) {
        url = Config.apiserver+jsonfile.urls.cashflowstmnt+"?tp=1&account_id="+accountID+"&date="+date;
    }

    const [state,setState] = useState({
        reqURL: url,
        filtervalue : '',
        subtitle : '',
    });

    const [account,setAccount] = useState({});
    const [hide,setHide] = useState([]);

    const ref = React.createRef();
    const formRef = React.createRef();

    const [apidata,setAPIData] = useState({});
    const [data,setData] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    useEffect(() => {
        if(CisUI().getUserInfo('com_id') > 0) {
            getLedgerData();
        }
    }, []);

    const getLedgerData = (url = "") => {
        document.getElementById("loader").style.display = "block";
        let urls = state.reqURL;
        if(url !== "") {
            urls = url;
        }

        axios.get(urls)
            .then((res) => {
                console.log(res)
                if (res.data.status === 1) {
                    setAPIData(res.data);
                } else {
                    setData({})
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                console.log(errors);
                // document.getElementById("loader").style.display = "none";
            })
            .finally(() => {
                setIsLoading(false);
            });

    }

    const getDetailsLink = (source,id) => {

        if(source === 'bill') {
            return "../../"+jsonfile.detaillink.bill+"/"+id;
        }
        else if(source === 'journal') {
            return "../../"+jsonfile.detaillink.journal+"/"+id;
        }
        else if(source === 'ledger') {
            return "../../"+jsonfile.detaillink.ledgerdetails+"/"+id;
        }
        else if(source === 'invoice') {
            return "../../"+jsonfile.detaillink.invoice+"/"+id;
        }
        else if(source === 'creditnote') {
            return "../../"+jsonfile.detaillink.creditnote+"/"+id;
        }
        else if(source === 'voucher') {
            return "../../"+jsonfile.detaillink.voucherdetails+"/"+id;
        }
        else if(source === 'transaction') {
            return "../../"+jsonfile.detaillink.transactiondetails+"/"+id;
        }
        else {
            return "../../"+jsonfile.detaillink.journal+"/"+id;
        }
    }

    const history = useHistory();

    const [form] = Form.useForm();

    const onFinish = values => {
        let wkd = "";
        let wk = "";
        let dt = "";
        const qs = Object.keys(values).map(key => {
                if (key === 'date') {
                    wk = formRef.current.getFieldValue('date');
                    const dt = CisUI().ParseDateFormat(values[key]);
                    return `${key}=${dt}`
                } else if (key === 'month') {
                    wk = formRef.current.getFieldValue('month');
                    // console.log("Selected Week : "+ wk);
                    wkd = CisUI().getMonthTitle(formRef.current.getFieldValue('month'));
                    return `${key}=${wkd[1]}`
                } else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        const qsup = qs.replaceAll("undefined", "");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?" + qsup);
        const newURL = Config.apiserver + jsonfile.urls.cashflowstmnt + "?tp=1&" + qsup;
        setData([]);
        setState({reqURL: newURL});

        getLedgerData(newURL);
    };



    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        ref={formRef}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.search,"",1,"",CisUI().PeriodFiled(form))}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {/*<Search*/}
            {/*    placeholder='Filter...'*/}
            {/*    name='filtervalue'*/}
            {/*    onSearch={handleOnFilter}*/}
            {/*    onChange={handleOnFilter}*/}
            {/*    style={{width: 200}}*/}
            {/*/>*/}

            {CisUI().listActionReportXLS("../"+jsonfile.urls.add,"",state.reqURL,"data","general_ledger",jsonfile,"Journal", "landscape")}
        </>
    );


    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (date,record) => (
                <>{record.type === 'entry' ? CisUI().DateFormat(date) : record.type === 'bb' ? <Link to={"bankactdetails?account_id="+record.id} style={{fontWeight: 'bold'}}>{record.account}</Link> : <></>}</>
            ),
        },
        {
            title: 'Account',
            dataIndex: 'account',
            key: 'account',
            render: (date,record) => (
                <>{record.type === 'entry' ? CisUI().DateFormat(date) : record.type === 'bb' ? <Link to={"bankactdetails?account_id="+record.id} style={{fontWeight: 'bold'}}>{record.account}</Link> : <></>}</>
            ),
        },
        {
            title: 'Type',
            dataIndex: 'source',
            key: 'source',
            render: (source,record) => (
                <>
                    {
                        record.link_id === 0 ? <span style={{fontWeight: 'bold'}}>{source}</span> : <Link to={getDetailsLink(record.link_to,record.link_id)}>{source}</Link>
                    }
                </>
            ),
        },
        {
            title: 'Description/Memo',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Ref.No./Check#',
            dataIndex: 'reference_no',
            key: 'reference_no',
        },
        {
            title: 'Inflow',
            dataIndex: 'debit',
            key: 'debit',
            align: 'right',
            width: '150px',
            render: (debit,record) => (
                <>
                    {
                        record.link_id === 0 ? <span style={{fontWeight: 'bold'}}>{CisUI().getCurrencyFormated1(debit)}</span> : <Link to={getDetailsLink(record.link_to,record.link_id)}>{(record.type === "entry" || record.type === "end") ? CisUI().getCurrencyFormated1(debit) : ""}</Link>
                    }
                </>
            ),
        },
        {
            title: 'Outflow',
            dataIndex: 'credit',
            key: 'credit',
            align: 'right',
            width: '150px',
            render: (credit,record) => (
                <>
                    {
                        record.link_id === 0 ? <span style={{fontWeight: 'bold'}}>{CisUI().getCurrencyFormated1(credit)}</span> : <Link to={getDetailsLink(record.link_to,record.link_id)}>{(record.type === "entry" || record.type === "end") ? CisUI().getCurrencyFormated1(credit) : ""}</Link>
                    }
                </>
            ),
        }
    ];

    return (
        <>
            {searchView}
            <Card title="Cashflow Statement">
                {CisUI().showLoading}

                {apidata?.data?.length > 0 ?
                    <>
                        <div className="row">
                            <div className="col-md-12" style={{textAlign: 'center'}}>
                                <h4 style={{textTransform: 'uppercase'}}>{CisUI().getUserInfo('companyName')}</h4>
                                <h3>Cash Book</h3>
                                <h4>For the period of {CisUI().getFormatedDate(apidata?.account?.fromDate)} to {CisUI().getFormatedDate(apidata?.account?.toDate)}</h4>
                            </div>
                            <hr />
                            <div className="col-md-4" style={{textAlign: 'right'}}></div>
                            <div className="col-md-4" style={{textAlign: 'right'}}></div>
                            <div className="col-md-4" style={{textAlign: 'right'}}>
                                <table className="summaryTable table" align={"right"} style={{width: '300px'}}>
                                    <tr>
                                        <th>Opening Balance</th>
                                        <td>{CisUI().getCurrencyFormated1(apidata?.opening)}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Inflow</th>
                                        <td>{CisUI().getCurrencyFormated1(apidata?.totalIn)}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Outflow</th>
                                        <td>{CisUI().getCurrencyFormated1(apidata?.totalOut)}</td>
                                    </tr>
                                    <tr>
                                        <th>Closing Balance</th>
                                        <td>{CisUI().getCurrencyFormated1(apidata?.closing)}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>



                        <div className="table-responsive">
                            <table className="statement table">
                                <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Account</th>
                                    <th>Description</th>
                                    <th>Type</th>
                                    <th>RefNo/Cheque#</th>
                                    <th style={{textAlign : 'right'}}>
                                        <div style={{textAlign: 'right'}}>Inflow ({CisUI().getCurrencySymbol()})</div>
                                    </th>
                                    <th style={{textAlign : 'right'}}><div style={{textAlign: 'right'}}>Outflow ({CisUI().getCurrencySymbol()})</div></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    apidata?.data?.map((itmes,index)=>{
                                        return <tr className={"disp_"+itmes.account_id} >
                                            <td><Link to={getDetailsLink(itmes.id)}>{itmes.date}</Link></td>
                                            <td><Link to={getDetailsLink(itmes.id)}>{itmes.account}</Link></td>
                                            <td><Link to={getDetailsLink(itmes.id)}>{itmes.description}</Link></td>
                                            <td><Link to={getDetailsLink(itmes.id)}>{itmes.remark}</Link></td>
                                            <td><Link to={getDetailsLink(itmes.id)}>{itmes.reference_no}</Link></td>
                                            <td style={{textAlign: 'right'}}><Link to={getDetailsLink(itmes.id)}>{CisUI().getCurrencyFormated1(itmes.debit)}</Link></td>
                                            <td style={{textAlign: 'right'}}><Link to={getDetailsLink(itmes.id)}>{CisUI().getCurrencyFormated1(itmes.credit)}</Link></td>
                                        </tr>
                                    })
                                }
                                <tr className="showTotalBorder">
                                    <td style={{fontWeight: 'bold'}} colSpan={5}>Total</td>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}}>{CisUI().getCurrencyFormated1(apidata?.totalIn)}</td>
                                    <td style={{textAlign: 'right', fontWeight: 'bold'}}>{CisUI().getCurrencyFormated1(apidata?.totalOut)}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                    </> : <h3 style={{textAlign: 'center'}}>No data found</h3>}

            </Card>
        </>
    );
};

export default CashflowStatementDetails;